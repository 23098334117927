<template>
  <div class="d-inline">
    <span
      class="mx-1 point a__effect"
      @click="$bvModal.show('changelog__modal')"
    >
      Note di rilascio
    </span>
    <b-modal
      scrollable
      id="changelog__modal"
      hide-footer
      size="xl"
      title="Note di rilascio"
    >
      <b-row v-for="(log, ind) in logs" :key="ind" class="my-2">
        <b-col cols="4" md="3" lg="2">
          <h4 class="text-center mb-0">
            <b-badge class="px-3 py-2 w-100" :variant="log.versionVariant"
              >{{ versionInitials[$root.scope] }}{{ log.version }}</b-badge
            >
          </h4>
        </b-col>
        <b-col>
          <b-row align-v="center">
            <h4 class="mt-1">
              {{ log.releaseDate }}
              <b-icon
                v-if="ind === 0"
                icon="award-fill"
                animation="throb"
                :variant="log.versionVariant"
              ></b-icon>
            </h4>
          </b-row>
          <b-row v-if="log.new.show">
            <b-col cols="5" md="4" lg="3">
              <h6>
                <b-badge class="px-3 py-2 w-100" variant="ter-1"
                  >NOVITA'</b-badge
                >
              </h6>
            </b-col>
            <b-col>
              <p class="text-justify" v-html="log.new.text"></p>
            </b-col>
          </b-row>
          <b-row v-if="log.improved.show">
            <b-col cols="5" md="4" lg="3">
              <h6>
                <b-badge class="px-3 py-2 w-100" variant="ter-2"
                  >MIGLIORAMENTI</b-badge
                >
              </h6>
            </b-col>
            <b-col>
              <p class="text-justify" v-html="log.improved.text"></p>
            </b-col>
          </b-row>
          <b-row v-if="log.fixed.show">
            <b-col cols="5" md="4" lg="3">
              <h6>
                <b-badge class="px-3 py-2 w-100" variant="sec-2"
                  >CORREZIONI</b-badge
                >
              </h6>
            </b-col>
            <b-col>
              <p class="text-justify" v-html="log.fixed.text"></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ChangeLogComponent",
  data() {
    return {
      logs: [
        {
          version: "01.02.01",
          versionVariant: "primary",
          releaseDate: "01 Dicembre 2024",
          new: {
            show: true,
            text: "<b>Monitoraggio</b>: servizio che permette il monitoraggio da remoto di parametri e score dei pazienti. <b>Blog</b>: le strutture sanitarie abilitate a specifici progetti possono generare e diffondere contenuti per gli utenti coinvolti. <b>Teleconsulto Rapido</b>: il nuovo servizio che mette a disposizione dei cittadini personale qualificato con tempi di risposta rapidi. <b>Centro Servizi</b>: operatori a disposizione dei cittadini per la gestione dei protocolli di monitoraggio e delle prenotazioni degli assistiti. <b>Acquisti in app</b>: integrato il sistema di pagamento Nexi per l'acquisto di prestazioni e sottoscrizioni ove previsto. <b>Libreria personalizzata</b> per l'operatore, per tenere a portata di mano tutti i collegamenti utili.",
          },
          improved: {
            show: true,
            text: "Migliorati per il cittadino l'agenda con la possibilità di inserire <b>eventi personalizzati</b> ed il Circolo di Cura con la funzionalità <b>\"Invita il mio medico\"</b>; inserito anche un chatbot per la ricerca del <b>centro vaccinale più vicino</b>. Miglioramenti sulle impostazioni di terapie e sulle configurazioni delle prestazioni da operatore. Aggiornamento dell'interfaccia grafica per una migliore accessibilità alla piattaforma. Miglioramenti finalizzati alla gestione delle attività di supporto da amministratore di piattaforma. Funzionalità per le attività di marketing.",
          },
          fixed: {
            show: false,
            text: "<b></b>:",
          },
        },
        {
          version: "01.01.09",
          versionVariant: "secondary",
          releaseDate: "29 Febbraio 2024",
          new: {
            show: false,
            text: "",
          },
          improved: {
            show: true,
            text: "Miglioramenti sulla validazione dei numeri di telefono.",
          },
          fixed: {
            show: true,
            text: "<b>Fix errore arruolamento senza anagrafica</b>: risolto l'errore che provocava l'arruolamento di un paziente senza anagrafica.",
          },
        },
        {
          version: "01.01.08",
          versionVariant: "secondary",
          releaseDate: "29 Gennaio 2024",
          new: {
            show: false,
            text: "",
          },
          improved: {
            show: true,
            text: "<b>Miglioramenti sulle prenotazioni</b>: aggiunti selettori per agevolare la scelta della data di prenotazione e la gestione dell'operatore dedicato; l'operatore può inoltre inserire una prenotazione pregressa. Miglioramenti sui report dei <b>questionari nella scheda paziente</b> e gestione dei codici fiscali di <b>soggetti nati all'estero</b>. Introdotti selettori per anno e mese nell'agenda per velocizzarne lo scorrimento.",
          },
          fixed: {
            show: true,
            text: "<b>Sicurezza</b>: gestione del timeout di sessione per l'operatore sanitario per scollegare l'utente inattivo.",
          },
        },
        {
          version: "01.01.07",
          versionVariant: "secondary",
          releaseDate: "05 Dicembre 2023",
          new: {
            show: true,
            text: "<b>Lista pazienti</b>: in area pazienti trovi adesso la lista dei tuoi assistiti, per un accesso alla scheda più rapido. <b>Gestione questionari</b>: puoi adesso modificare ed eliminare i questionari somministrati. <b>Codici esenzione</b>: introdotta la gestione dei codici esenzione nella sezione Patologie della scheda paziente. <b>Sezione dispositivi</b> nella scheda paziente: visualizzazione dei dispositivi indossabili acquistati dal paziente.",
          },
          improved: {
            show: true,
            text: "<b>Migliorata la selezione dello slot di prenotazione</b>: inseriti selettore anno e mese per velocizzare la selezione dello slot di prenotazione. <b>Più opzioni per le notifiche di promemoria</b>: inserite più opzioni per l'invio notifiche di promemoria, per i monitoraggi a lungo termine. <b>Aggiornamento anagrafica paziente</b>: introdotto i campi facoltativi dedicati al medico di base e all'azienda sanitaria locale del paziente.",
          },
          fixed: {
            show: true,
            text: "<b>Validazione numero di telefono</b>: verifica sulla formattazione del numero di telefono in caso di inserimento di prefisso. <b>Ricezione notifiche push</b>: puoi ricevere le notifiche push su tutti i dispositivi da cui hai eseguito l'accesso.",
          },
        },
      ],
      versionInitials: {
        concura: "CC",
        puntofarma: "PF",
      },
    };
  },
};
</script>
